@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-SemiBold.eot');
    src: local('Noto Sans SemiBold'), local('NotoSans-SemiBold'),
        url('NotoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-SemiBold.woff2') format('woff2'),
        url('NotoSans-SemiBold.woff') format('woff'),
        url('NotoSans-SemiBold.ttf') format('truetype'),
        url('NotoSans-SemiBold.svg#NotoSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Medium.eot');
    src: local('Noto Sans Medium'), local('NotoSans-Medium'),
        url('NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-Medium.woff2') format('woff2'),
        url('NotoSans-Medium.woff') format('woff'),
        url('NotoSans-Medium.ttf') format('truetype'),
        url('NotoSans-Medium.svg#NotoSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Bold.eot');
    src: local('Noto Sans Bold'), local('NotoSans-Bold'),
        url('NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-Bold.woff2') format('woff2'),
        url('NotoSans-Bold.woff') format('woff'),
        url('NotoSans-Bold.ttf') format('truetype'),
        url('NotoSans-Bold.svg#NotoSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Regular.eot');
    src: local('Noto Sans Regular'), local('NotoSans-Regular'),
        url('NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-Regular.woff2') format('woff2'),
        url('NotoSans-Regular.woff') format('woff'),
        url('NotoSans-Regular.ttf') format('truetype'),
        url('NotoSans-Regular.svg#NotoSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

